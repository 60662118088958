

























































import { Component, Vue } from 'vue-property-decorator';
import YwhzSelfDeliveryType from '@/components/PortalSite/YwhzSelectCard/SelfDeliveryType.vue';
import YwzhCarryRegion from '@/components/PortalSite/YwhzRegionCard/CarryRegion.vue';

import { GetHKStationList } from '@/api/select';
import { searchConditionalTypeEnum } from '@/utils/type-enum';

@Component({
    name: 'ThirdSelfPromotion',
    components: {
        YwhzSelfDeliveryType,
        YwzhCarryRegion
    }
})
export default class ThirdSelfPromotion extends Vue {
    // 这里利用v-if 重新加载地区组件内容
    public isShowDeliveryType: boolean = false;

    public list: any = [];

    public formData: any = {
        SelfType: '',
        AddressArr: []
    };

    public Total: number = 0;

    private params: any ={
        PageIndex: 1,
        PageSize: 10,
        Expressionable: []
    };

    private created() {
        this.init();
    }

    public async init() {
        try {

            this.params.Expressionable = [];
            if (this.formData.SelfType !== '') {
                this.params.Expressionable.push({ FieldName: 'StationTypeId', FieldValue: this.formData.SelfType, ConditionalType: searchConditionalTypeEnum['等于'] });
            }
            this.params.Expressionable.push({ FieldName: 'IsEnable', FieldValue: 1, ConditionalType: searchConditionalTypeEnum['等于'] });

            if (this.formData.AddressArr.length) {
                const val = this.formData.AddressArr[this.formData.AddressArr.length - 1];
                this.params.Expressionable.push({ FieldName: 'AreaId', FieldValue: val, ConditionalType: searchConditionalTypeEnum['等于'] });
            }
            this.params.Expressionable = JSON.stringify(this.params.Expressionable);
            const params = JSON.parse(JSON.stringify(this.params));
            const { DataList, Total } = await GetHKStationList(params);
            this.Total = Total;
            DataList.map((item) => {
                item.IsEnable1 = item.IsEnable === 1 ? '啟用' : '未啟用';
                return item;
            });
            this.list = DataList;
        } catch (error) {
            console.warn(error);
        }
    }

    public DeliveryTypeChange() {
        this.isShowDeliveryType = false;
        if (this.formData.SelfType !== '') {
            setTimeout(() => {
                this.isShowDeliveryType = true;
            }, 300);
        }
        this.formData.AddressArr = [];
        this.init();
    }

    public currentChange(page) {
        this.params.PageIndex = page;
        this.init();
    }
}
